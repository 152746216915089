/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
    color: #333;
}

/* Career Section */
.career-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.career-section h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #333;
}



/* Filter Bar */
.filter-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0 20px;
}

.filter-bar input[type="search"],
.filter-bar select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    min-width: 200px;
    max-width: 300px;
    flex-grow: 1;
}

/* Job Listings Section */
.job-listings {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 20px;
}

.job-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    text-align: center;
}

.job-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007BFF;
}

.job-card p {
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
}

.job-card a {
    text-decoration: none;
    color: #007BFF;
    font-weight: bold;
}

.job-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive Breakpoints */
@media (max-width: 768px) {
    .career-section h1 {
        font-size: 1.8rem;
    }

    .filter-bar input[type="search"],
    .filter-bar select {
        min-width: 100%;
    }

    .filter-bar {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .career-section h1 {
        font-size: 1.5rem;
    }

    .job-card h3 {
        font-size: 1.3rem;
    }

    .job-listings {
        grid-template-columns: 1fr;
    }

    .filter-bar {
        padding: 0;
    }
}
