.screenshot-container {
    width: 100%; /* Set container width to 80% */
    margin: 0 auto; /* Center the container */
    text-align: center;
    background-color: #0f4a5a; /* Dark background to match UI */
    padding: 40px 0;
  }
  
  .screenshot-title {
    font-size: 32px; /* Increase font size */
    font-weight: bold;
    color: white; /* Match the text color */
    margin-bottom: 30px;
  }
  
  .screenshot-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .screenshot-image {
    width: 70%; /* Set image width to 70% */
    height: auto;
    border-radius: 10px; /* Optional: Adds rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds depth effect */
  }
  