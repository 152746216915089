/* Main Theme Colors */
:root {
    --primary-color: #1a73e8;
    --secondary-color: #f9fafc;
    --text-color: #333;
    --hover-color: #1669c1;
    --header-bg: #ffffff;
    --accent-color: #ff6b6b;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --border-radius: 12px;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--secondary-color);
    color: var(--text-color);
}

/* Remove blue underline from links */
a {
    color: inherit; /* Inherits text color */
    text-decoration: none;
}

/* Header section styles */
.header-section {
    text-align: center;
    padding: 30px 20px;
    background-color: #000000;
    color: #ffffff;
    margin-bottom: 40px;
    box-shadow: 0 8px 16px var(--shadow-color);
    animation: fadeIn 1s ease;
}

.header-title {
    font-size: 42px;
    font-weight: 700;
}

.header-subtitle {
    font-size: 20px;
    font-weight: 400;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.holiday-box {
    width: 300px;
    height: 300px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 16px var(--shadow-color);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
    padding: 20px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    position: relative;
}

.holiday-box::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
}

/* Background images for each event */
.womens-day-box::after {
    background-image: url('../assets/images/womansday.jpg');
}

.christmas-box::after {
    background-image: url('../assets/images/cristmas.jpg');
}

.independence-day-box::after {
    background-image: url('../assets/images/independentday.jpg');
}

.mens-day-box::after {
    background-image: url('../assets/images/mensday.jpg');
}

.mumbai-university-box::after {
    background-image: url('../assets/images/mumbaiuniversity.jpg');
}

.new-section-2-box::after {
    background-image: url('../assets/images/diwali.jpg');
}

.new-section-3-box::after {
    background-image: url('../assets/images/travel.jpg');
}

.new-section-4-box::after {
    background-image: url('../assets/images/auditorium.jpg');
}

.new-section-5-box::after {
    background-image: url('../assets/images/specialevent.jpg');
}

.holiday-box:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.holiday-box:hover::after {
    opacity: 0.6;
}

.box-content {
    position: relative;
    z-index: 1;
}

.box-title {
    font-size: 24px;
    font-weight: 600;
    color: red;
}

.box-description {
    font-size: 16px;
    color: #ffffff;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
