/* General styling for the content section */
.content-section {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    max-width: 800px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for the header */
.content-section h1 {
    color: #2a9d8f;
    font-size: 2.5em;
    font-weight: 600;
    margin: 20px 0;
}

/* Image styling */
.attestation {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* List styling */
.info-list {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    max-width: 700px;
}

.info-list li {
    background: #fff;
    border-left: 4px solid #2a9d8f;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    font-size: 1.1em;
    transition: all 0.3s ease;
}

/* Hover effect */
.info-list li:hover {
    background-color: #f0f4f8;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Text highlight */
.info-list li strong {
    color: #2a9d8f;
    font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .content-section h1 {
        font-size: 2em;
    }
    .info-list li {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .content-section h1 {
        font-size: 1.8em;
    }
    .info-list li {
        font-size: 0.9em;
        padding: 15px;
    }
}
