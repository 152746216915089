.content-section {
    font-family: 'Poppins', sans-serif;
    line-height: 1.7;
    background: linear-gradient(135deg, #f0f4f8, #d9e4f5);
    color: #333;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}

.attestation {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.content-section h1 {
    color: #2a9d8f;
    font-size: 2.5em;
    font-weight: 600;
    margin: 20px 0;
    letter-spacing: 1px;
}

/* List item styling (shared style) */
ul.info-list li {
    background-color: #fff;
    border-left: 4px solid #2a9d8f;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    transition: all 0.3s ease;
}

ul.info-list li:hover {
    background-color: #f9f9f9;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

ul.info-list li strong {
    color: #2a9d8f;
    font-weight: 600;
    font-size: 1.1em;
}
