/* MensDayCelebration.css */

.content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.content img {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 12px;
    margin: 20px 0;
    object-fit: cover;
}

.content h1 {
    color: #2c3e50;
    font-size: 24px;
}

.content p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 20px;
    text-align: justify;
}

/* Responsive Design */
@media (max-width: 768px) {
    .content {
        padding: 10px;
    }
}
