/* src/Styles/UniversityCelebration.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    color: #34495e;
}

h1, h2 {
    color: #2c3e50;
}

.content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.content p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 20px;
    text-align: justify;
}

.content img {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 12px;
    margin: 20px 0;
    object-fit: cover;
}

#header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 4px 2px -2px gray;
}

@media (max-width: 768px) {
    .content {
        padding: 10px;
    }
}
