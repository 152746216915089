/* Basic Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
}

/* Header Styles */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    background-color:#e3f1ff; /* Darker background for professional look */
    border-bottom: 2px solid #0056b3;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.logo img {
    height: 50px;
}

/* Navigation Styles */
.nav-menu {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    transition: max-height 0.3s ease;
}

.nav-menu a {
    padding: 10px 15px;
    color: black; /* White color for better contrast */
    text-decoration: none;
    font-weight: bold;
    border-radius: 4px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-menu a:hover {
    background-color: #0056b3; /* Professional blue accent on hover */
    color: #fff;
}

/* Dropdown Styles */
.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #e2f0ff; /* Light blue background for dropdown */
    min-width: 220px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px 0;
    z-index: 1;
    text-align: center; /* Center-align text in dropdown */
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    padding: 12px 16px;
    color: #0056b3; /* Blue color for dropdown text */
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content in mobile view */
    gap: 10px;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.dropdown-content a:hover {
    background-color: #007bff; /* Darker blue for hover effect */
    color: #ffffff;
}

/* Buttons */
.buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #ffffff;
}

.btn-primary {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
    color:#000000;
}

@media (max-width: 768px) {
    /* Hide the nav-menu by default on mobile */
    .nav-menu {
        display: none;
    }

    /* Show nav menu when active */
    .nav-menu.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #9bc8fd;
        padding: 20px;
        box-shadow: 0px 4px 8px rgba(247, 0, 0, 0.2);
        z-index: 1000;
    }

    /* Center align links and add spacing */
    .nav-menu a {
        padding: 10px 0;
        text-align: center;
        color: red;
        text-decoration: none;
        font-weight: bold;
    }

    /* Center dropdown content in mobile */
    .dropdown-content {
        position: relative;
        left: 50%; /* Move dropdown to the center */
        transform: translateX(-50%);
    }

    /* Show hamburger icon only on mobile */
    .hamburger {
        display: block;
    }
}

@media (max-width: 768px) {
    .search-btn,
    .btn-primary {
        display: none;
    }

    .nav-menu {
        display: none;
    }

    .nav-menu.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 86px;
        left: 0;
        width: 100%;
        background-color: #a2cdff;
        padding: 20px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1000;
    }

    .nav-menu a {
        padding: 10px 0;
        text-align: center;
        color: red;
        text-decoration: none;
        font-weight: bold;
    }

    .dropdown-content {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .hamburger {
        display: block;
    }
}


/* Search Box */
.search-box {
    position: absolute;
    top: 60px;
    right: 100px;
    background: #fff;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    z-index: 1000;
}

.search-box input {
    width: 200px;
    padding: 8px;
    border: none;
    outline: none;
    font-size: 14px;
}

.search-box .close-search {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-left: 8px;
    color: #666;
}

/* Adjust button */
.search-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
}
