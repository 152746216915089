/* General content section styling */
.content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f7ff;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    text-align: center;
}

/* Image styling */
.attestation {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
.content-section h1 {
    color: #2a9d8f;
    font-size: 2.5em;
    font-weight: 600;
    margin: 20px 0;
    letter-spacing: 1px;
}

/* List styling */
.info-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    max-width: 700px;
    width: 100%;
}

.info-list li {
    background-color: #fff;
    border-left: 4px solid #2a9d8f;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    transition: all 0.3s ease;
}

.info-list li:hover {
    background-color: #f9f9f9;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.info-list li strong {
    color: #2a9d8f;
    font-weight: 600;
}
