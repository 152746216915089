/* Reset and Body Styling */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background: #f9fbfd;
    color: #333;
}

/* Section Title */
h2 {
    font-size: 36px;
    color: #2c3e50;
    margin-bottom: 40px;
    text-align: center;
}

/* Section Styles */
.about-section {
    padding: 60px 20px;
    background: #e2f0ff;
}

/* Responsive Card Grid Layout */
.about-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Flexible columns */
    gap: 20px;
}

/* Card Styles */
.about-content div {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 400px; /* Ensures the same height for all cards */
}

/* Card Hover Effect */
.about-content div:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

/* Card Title */
.about-content h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #2c3e50;
}

/* Card Description */
.about-content p {
    font-size: 16px;
    color: #666;
    line-height: 1.8;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Image Styles */
.about-content img {
    width: 100%;
    height: 150px; /* Fixed height for uniform image size */
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

/* Image Hover Effect */
.about-content div:hover img {
    transform: scale(1.05);
}

/* Responsive Text Scaling */
@media (max-width: 768px) {
    h2 {
        font-size: 28px;
    }
    .about-content h3 {
        font-size: 20px;
    }
    .about-content p {
        font-size: 15px;
    }
}




.about-story-text,
.about-mission-text,
.about-group-text {
    text-align: center;
}
