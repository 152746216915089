/* MediumInstructionLetter.css */
.content-section {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.attestation {
   
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.content-section h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 16px;
}

.content-section ul {
    list-style-type: none;
    padding: 0;
}

.content-section li {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1.5;
}

.content-section li strong {
    color: #333;
}
