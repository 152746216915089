.solution-container {
    position: relative;
    background: url('../assets/images/blue\ plane.jpg') center/cover no-repeat;
    padding: 100px 20px;
    text-align: center;
}

.overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.6); /* Darker overlay for better readability */
}

.solution-title {
    position: relative;
    font-size: 40px;
    font-weight: bold;
    color: white;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

/* Solution Grid */
.solution-grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    width: 80%; /* Making the width 80% */
    margin: 0 auto; /* Centering the grid */
}

/* Solution Cards */
.solution-card {
    background: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
    width: 320px; /* Giving a uniform width */
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
}

.solution-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    border-color: #007BFF;
}

/* Card Title */
.solution-card h3 {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
}

/* Card Text */
.solution-card p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
    .solution-grid {
        width: 90%;
        gap: 20px;
    }

    .solution-card {
        width: 100%;
        padding: 20px;
    }

    .solution-title {
        font-size: 32px;
    }
}
