/* Slider.css */

.slider-container {
    position: relative;
    width: 100%;
    height: 590px; /* Adjust height as needed */
    overflow: hidden;
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%; /* Ensure the slider takes the full height */
}

.slide {
    min-width: 100%; /* Each slide takes full width */
    height: 100%; /* Ensure the image fills the slide */
}

.slide img {
    width: 100%;
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover; /* Crop image to fill */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.mission-heading {
    font-size: 2rem;
    color: whitesmoke;
    text-align: center;
}

.mission-statement {
    font-size: 1.25rem;
    text-align: center;
    color: whitesmoke;
}

.highlight {
    color: #ffcc00; /* Example highlight color */
}
