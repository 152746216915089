body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .footer {
    background-color: #e2f0ff; /* Fallback background color */
    /* background-image: url('/image/footerbackground.jpg'); Adjusted for public folder */
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    padding: 40px 0 80px;
    position: relative;
    overflow: hidden;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .footer-logo img {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-address {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .demo-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .footer-links {
    display: flex;
    gap: 60px;
  }
  
  .footer-column h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-column li {
    margin-bottom: 10px;
  }
  
  .footer-column a {
    color: #666;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-column a:hover {
    color: #007bff;
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 40px;
    text-align: center;
  }
  
  .social-icons {
    margin-top: 20px;
  }
  
  .social-icons img {
    width: 24px;
    margin: 0 10px;
  }
  
  .footer-decoration {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #4CAF50;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
  }
  
  .footer-image {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .footer-image img {
    width: 120px;
    margin-bottom:-29px;
  }



  /* Responsive styles for mobile devices (max-width: 450px) */
@media (max-width: 450px) {
  .footer {
    padding: 30px 0 70px;
  }
  
  .footer-content {
    flex-direction: column;
    padding: 0 15px;
    gap: 25px;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .footer-logo img {
    width: 120px;
    margin-bottom: 15px;
  }
  
  .footer-address {
    text-align: center;
    font-size: 13px;
    max-width: 280px;
  }
  
  .demo-button {
    margin-top: 15px;
    width: 80%;
    max-width: 200px;
  }
  
  /* Keep links in a row with 2 columns */
  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 15px;
    width: 100%;
  }
  
  .footer-column {
    text-align: center;
  }
  
  .footer-column h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 8px;
    font-size: 13px;
  }
  
  .footer-bottom {
    margin-top: 25px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icons img {
    width: 20px;
  }
  
  .footer-image {
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
  }
  
  .footer-image img {
    width: 90px;
    margin-left: -186px;
  }
}


/* Responsive styles for tablets (max-width: 767px) */
@media (max-width: 767px) {
  .footer {
    padding: 35px 0 75px;
  }
  
  .footer-content {
    flex-direction: column;
    padding: 0 20px;
    gap: 30px;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .footer-logo img {
    width: 130px;
    margin-bottom: 18px;
  }
  
  .footer-address {
    text-align: center;
    font-size: 14px;
    max-width: 320px;
  }
  
  .demo-button {
    margin-top: 18px;
    width: 70%;
    max-width: 220px;
  }
  
  /* Keep links in a row with 2 columns */
  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px 20px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .footer-column {
    text-align: center;
  }
  
  .footer-column h3 {
    font-size: 17px;
    margin-bottom: 12px;
  }
  
  .footer-column ul {
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .footer-bottom {
    margin-top: 30px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 18px;
  }
  
  .social-icons img {
    width: 22px;
  }
  
  .footer-image {
    left: 50%;
    transform: translateX(-50%);
    bottom: 28px;
  }
  
  .footer-image img {
    width: 100px;
    
  }
}