/* Projects.css */
.projects-container {
    text-align: center;
    padding: 40px 20px;
    background-color: #f9fafc;
}

.projects-heading {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
}

.projects-heading::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: #00a8ff;
    margin: 8px auto 0;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Fixed 5 columns */
    gap: 20px;
    width: 80%;
    margin: 0 auto;
    justify-items: center;
}

.project img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
}

.project img:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(0, 168, 255, 0.3);
}

/* Responsive for Mobile View */
@media (max-width: 768px) {
    .projects-grid {
        display: flex; /* Switch to flexbox */
        flex-wrap: nowrap; /* Prevent wrapping */
        overflow-x: scroll; /* Enable horizontal scrolling */
        gap: 15px; /* Adjust gap for smaller screens */
        padding: 10px; /* Add padding for scroll area */
    }

    .project {
        flex: 0 0 calc(50% - 15px); /* Show 2 items in viewport */
        margin: 0 7.5px; /* Adjust margin between images */
    }

    .project img {
        width: 120px; /* Adjust size for mobile */
        height: 120px; /* Adjust size for mobile */
    }

    .projects-heading {
        font-size: 24px; /* Slightly smaller font for heading */
    }
}
