.streamline-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
    margin: 2rem auto;
    max-width: 1200px;
}
  
.streamline-section-content {
    max-width: 50%;
}
  
.streamline-section-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: left;
}
  
.streamline-section-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
}
  
.streamline-section-text {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    margin-bottom: 2rem;
}
  
.streamline-section-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.streamline-section-button:hover {
    background-color: #0056b3;
}
  
.streamline-section-image {
    max-width: 45%;
}
  
.streamline-section-curved-image {
    border-radius: 10px;
    width: 100%;
    height: 450px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
    .streamline-section-container {
        flex-direction: column;
        text-align: center;
        padding: 1.5rem;
    }

    .streamline-section-content {
        max-width: 100%;
    }

    .streamline-section-title {
        font-size: 1.8rem;
        text-align: center;
    }

    .streamline-section-subtitle {
        font-size: 1rem;
    }

    .streamline-section-text {
        font-size: 0.9rem;
        text-align: justify;
        text-justify: inter-word;
    }

    .streamline-section-image {
        max-width: 100%;
        margin-top: 1.5rem;
    }

    .streamline-section-curved-image {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .streamline-section-curved-image {
        height: 250px;
        width: 350px;

    }
}
