.client-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f8f9fa; /* Light gray background */
  }
  
  .client-title {
    font-size: 2rem;
    font-weight: bold;
    color: #222; /* Darker text for contrast */
    margin-bottom: 30px;
  }
  
  .client-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px; /* Adds spacing between logos */
  }
  
  .client-logo {
    width: 180px; /* Set a fixed width */
    height: 180px; /* Set a fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    background: white; /* Optional: adds a background behind logos */
    border-radius: 10px; /* Optional: rounded corners */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    transition: transform 0.3s ease-in-out;
    overflow: hidden; /* Ensures images don’t exceed their div */
  }
  
  .client-logo img {
    width: 100%;  /* Ensures image covers full width */
    height: 100%; /* Ensures image covers full height */
    object-fit: contain; /* Ensures full visibility while maintaining aspect ratio */
  }
  
  .client-logo:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
  