/* Container */
.aboutedu-container {
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #000; /* Set text color to black */
    background-color: #ffffff; /* Set background color to white */
}

/* Main title */
.aboutedu-header {
    font-size: 2.5rem;
    color: #000; /* Set title color to black */
    text-align: center;
    margin-top: 20px;
    background-color: lightblue;
    padding: 15px;
    
}

/* Overview Section */
.aboutedu-overview {
    background-color: #ffffff;
    padding: 40px;
    max-width: 100%;
    margin: 20px auto;
}

/* Section title */
.aboutedu-title {
    font-size: 2em;
    color: #000; /* Set title color to black */
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
}

/* Paragraphs */
.aboutedu-text {
    width: 80%;  /* Set width to 80% */
    margin: 20px auto; /* Center the text block and add spacing */
    font-size: 1.1em;
    line-height: 1.8; /* Improved line spacing for readability */
    color: #000; /* Set text color to black */
    text-align: justify;
    text-justify: inter-word;
    padding: 0; /* Remove padding */
    background: none; /* Remove background */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove shadow */
}

/* Responsive styles for tablets */
@media (max-width: 992px) {
    .aboutedu-container {
        padding: 30px 15px;
    }

    .aboutedu-header {
        font-size: 2.2em;
    }

    .aboutedu-title {
        font-size: 1.8em;
    }

    .aboutedu-overview {
        padding: 30px;
    }

    .aboutedu-text {
        width: 90%;  /* Adjust width for smaller screens */
        font-size: 1em;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .aboutedu-container {
        padding: 0px 0px;
    }

    .aboutedu-header {
        font-size: 2em;
    }

    .aboutedu-title {
        font-size: 1.6em;
    }

    .aboutedu-overview {
        padding: 20px;
        margin-bottom: 15px;
    }

    .aboutedu-text {
        width: 95%;
        font-size: 0.95em;
    }
}

/* Responsive styles for small mobile devices */
@media (max-width: 480px) {
    .aboutedu-container {
        padding: 0px 0px;
    }

    .aboutedu-header {
        font-size: 1.8em;
    }

    .aboutedu-title {
        font-size: 1.4em;
    }

    .aboutedu-overview {
        padding: 15px;
        margin-bottom: 10px;
    }

    .aboutedu-text {
        width: 100%;
        font-size: 0.9em;
        line-height: 1.6;
    }
}
