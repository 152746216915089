.achievements-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #f9fbfd;
}

.achievements-section h1 {
    font-size: 36px;
    color: #007bff;
    font-weight: bold;
    margin-bottom: 40px;
    text-transform: uppercase;
    position: relative;
}

.achievements-section h1::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: #007bff;
    margin: 10px auto 0;
}

.achievements-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.achievement-card {
    background-color: #e2f0ff;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.achievement-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.achievement-card img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 20px;
}

.achievement-card h2 {
    font-size: 20px;
    color: #007bff;
    margin-bottom: 10px;
}

.achievement-card p {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    text-align: center;
}

@media (max-width: 768px) {
    .achievements-section h1 {
        font-size: 28px;
    }
    .achievement-card h2 {
        font-size: 18px;
    }
    .achievement-card p {
        font-size: 15px;
    }
}
