/* Support Section */
.support-container {
    width: 80%;
    margin: auto;
    text-align: left;
    padding: 40px 0;
  }
  
  .support-title {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    text-align: left;
    margin-bottom: 30px;
  }
  
  .support-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .support-item {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .support-number {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    background: #ececec;
    padding: 15px 25px;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .support-content {
    flex: 1;
  }
  
  .support-heading {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
  }
  
  .support-description {
    font-size: 16px;
    color: #444;
    line-height: 1.5;
  }
  