/* Process.css */
.process-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .process-card {
    background: #f9f9f9;
    border: 2px solid #004c66;
    border-radius: 12px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .process-card.expanded {
    padding-bottom: 20px;
  }
  
  .process-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding-bottom: 10px;
  }
  
  .toggle-icon {
    font-size: 18px;
    font-weight: bold;
  }
  
  .process-content {
    margin-top: 10px;
    text-align: left;
    padding: 10px;
    border-top: 1px solid #004c66;
  }
  
  .step {
    display: flex;
    align-items: flex-start;
    margin: 15px 0;
  }
  
  .step-number {
    background: #004c66;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  h4 {
    margin: 5px 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }
  