/* src/styles/Home.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

section {
    padding: 2rem 1rem;
}

@media (max-width: 768px) {
    section {
        padding: 1rem;
    }
}
