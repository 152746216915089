/* Updated Logo Slider Styles for Uniform Logo Sizes */
.partners-section {
    text-align: center;
    margin: 50px 0;
}

.logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Reduced gap between logos */
    flex-wrap: wrap; /* Makes logos wrap to the next line for responsiveness */
}

.logo-item {
    width: 150px; /* Uniform square size */
    height: 150px; /* Uniform square size */
    background-color: white;
    padding: 10px; /* Slight padding for aesthetic */
    border-radius: 10px; /* Rounded corners for style */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 10px; /* Reduced margin for spacing between logos */
}

.logo-item img {
    width: 130px; /* Uniform width for all logos */
    height: 130px; /* Uniform height for all logos */
    object-fit: contain; /* Ensure logos fit within the size without distortion */
    display: block;
    margin: auto; /* Center the image */
}
