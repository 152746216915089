/* Styles for the Team Section */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f7ff;
}

.team-section {
    text-align: center;
    padding: 40px;
    background-color: #f0f7ff;
    margin: 0 auto;
}

.team-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.team-section h1::after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background-color: #1a73e8;
    margin: 10px auto 20px auto;
}

.team-members {
    display: flex;
    justify-content: center; /* Center the team members horizontally */
    gap: 20px;
    flex-wrap: wrap;
    width: 100%; /* Set width to full */
    margin: 0 auto;
    text-align: center;
}

.team-member {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(25% - 20px); /* Adjust to fit 4 cards per row */
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
}

.team-member:hover {
    transform: translateY(-5px); /* Slight lift on hover */
}

.team-member img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #1a73e8;
    margin-bottom: 15px;
}

.team-member h2 {
    font-size: 1.3em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
}

.team-member p {
    font-size: 1em;
    color: #666;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .team-member {
        width: 100%; /* Stack cards vertically on small screens */
    }
}
.team-member:active {
    background-color: #1a73e8; /* Blue background on click */
    color: #fff; /* White text for contrast */
}

.team-member:active h2, .team-member:active p {
    color: #fff; /* Ensure text inside the card also turns white on click */
}
