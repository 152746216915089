/* src/Styles/MigrationInfo.css */

.content-section {
    max-width: 80%; /* Set the section to take 80% of the width */
    margin: 0 auto; /* Center the section horizontally */
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.migration {
    width: 100%; /* Make the image take full width of the container */
    max-width: 600px; /* Set a maximum width for the image */
    height: auto;
    display: block;
    margin: 0 auto 20px; /* Center the image and add space below */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

h1 {
    font-size: 2rem;
    text-align: center;
    color: #007bff; /* Set a primary color */
    margin-bottom: 20px;
    font-weight: bold;
}

.info-list {
    list-style-type: none;
    padding: 0;
}

.info-list li {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
    text-align: justify;
}

.info-list li strong {
    color: #333; /* Darker color for the bold text */
}
