.why-choose-us-section {
    padding: 50px 0;
    background-color: #f9f9f9;
    text-align: center;
}

.why-choose-us-title {
    margin-bottom: 30px;
    background-color: lightblue;
}

.why-choose-us-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.why-choose-us-card {
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
    min-width: 200px; /* Ensures a reasonable minimum width */
}

.why-choose-us-card:hover {
    transform: translateY(-10px);
}

.why-choose-us-card-icon {
    font-size: 40px;
    margin-bottom: 15px;
}

.why-choose-us-card h3 {
    font-size: 1.25em;
    margin-bottom: 10px;
    color: #333;
}

.why-choose-us-card p {
    font-size: 0.95em;
    color: #666;
}
.why-choose-us-subtitle {
    text-align: center;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .why-choose-us-container {
        flex-wrap: wrap; /* Allow cards to wrap */
    }
    .why-choose-us-card {
        flex: 1 1 45%; /* Two cards per row on medium screens */
    }
}

@media (max-width: 480px) {
    .why-choose-us-card {
        flex: 1 1 100%; /* One card per row on small screens */
    }
}
