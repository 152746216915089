

/* Contact Section */
.unique-contact-us {
    padding: 50px 20px;
    max-width: 1100px;
    margin: auto;
}

/* Header */
.unique-contact-header {
    text-align: center;
    margin-bottom: 40px;
}

.unique-contact-header h1 {
    font-size: 42px;
    font-weight: bold;
    color: #000000;
}

.unique-contact-header p {
    font-size: 18px;
    color: #f0f0f0;
}

/* Contact Info */
.unique-contact-info {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.unique-contact-card,
.unique-contact-card-email,
.unique-contact-card-address {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.unique-contact-card:hover,
.unique-contact-card-email:hover,
.unique-contact-card-address:hover {
    transform: translateY(-8px);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}

.unique-contact-card i,
.unique-contact-card-email i,
.unique-contact-card-address i {
    font-size: 30px;
    color: #0073e6;
    margin-bottom: 10px;
}

.unique-contact-card h3,
.unique-contact-card-email h3,
.unique-contact-card-address h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.unique-contact-card p,
.unique-contact-card-email p,
.unique-contact-card-address p {
    font-size: 16px;
    color: #555;
}

.unique-contact-card p a,
.unique-contact-card-email p a,
.unique-contact-card-address p a {
    color: #0073e6;
    text-decoration: none;
    font-weight: 600;
}

.unique-contact-card p a:hover,
.unique-contact-card-email p a:hover,
.unique-contact-card-address p a:hover {
    color: #004a99;
}

/* Contact Form & Map */
.unique-contact-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;
}

/* Contact Form */
.unique-contact-form {
    flex: 1;
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    min-width: 320px;
}

.unique-contact-form h2 {
    font-size: 24px;
    color: #0073e6;
    margin-bottom: 20px;
}

.unique-contact-input,
.unique-contact-email,
.unique-contact-textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
}

.unique-contact-textarea {
    height: 120px;
}

.unique-contact-button {
    background: #0073e6;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.unique-contact-button:hover {
    background: #005bb5;
}

/* Map */
.unique-contact-map {
    flex: 1;
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: 320px;
}

.unique-contact-map h2 {
    font-size: 24px;
    color: #0073e6;
    margin-bottom: 20px;
}

.unique-contact-map iframe {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: none;
}

/* Responsive Design */
@media (max-width: 768px) {
    .unique-contact-container {
        flex-direction: column;
        align-items: center;
    }
}
