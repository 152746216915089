/* Auditorium.css */
.auditorium {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .auditorium p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .auditorium img {
    width: 100%;
    max-width: 800px; /* Maximum width of the image */
    height: auto;
    border-radius: 12px; /* Rounded corners for the image */
    margin: 20px 0; /* Margin for spacing around the image */
    object-fit: cover; /* Ensures the image covers the space nicely */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .auditorium {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }
  