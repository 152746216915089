.key-feature-container {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 80%;
    margin: auto;
    padding: 50px 0;
}

.feature-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-image img {
    width: 100%;
    height: 500px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image scales properly without distortion */
    border-radius: 12px;
}

.feature-content {
    flex: 1;
}

.feature-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.feature-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.feature-number {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-text h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.feature-text p {
    font-size: 14px;
    color: #555;
}

@media (max-width: 768px) {
    .key-feature-container {
        flex-direction: column;
        text-align: center;
        width: 90%;
        padding: 30px 0;
    }

    .feature-image {
        order: -1;
        width: 100%;
    }

    .feature-image img {
        height: 300px; /* Reduce image height */
    }

    .feature-content {
        width: 100%;
    }

    .feature-title {
        font-size: 28px;
    }

    .feature-list {
        gap: 15px;
    }

    .feature-item {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .feature-number {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    .feature-text h3 {
        font-size: 16px;
    }

    .feature-text p {
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .feature-image img {
        height: 250px;
    }

    .feature-title {
        font-size: 24px;
    }

    .feature-text p {
        font-size: 12px;
    }
}
