.verificationInfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f7ff; /* Light background color */
    padding: 20px;
    border-radius: 8px;
}

.verificationInfo-image {
    width: 300px; /* Increased size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.verificationInfo-title {
    font-size: 1.8em;
    color: #2f855a; /* Green color */
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.verificationInfo-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
    width: 100%;
}

/* List item styling (shared style) */
.verificationInfo-item {
    background-color: #fff;
    border-left: 4px solid #2a9d8f;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    transition: all 0.3s ease;
}

.verificationInfo-item:hover {
    background-color: #f9f9f9;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.verificationInfo-item strong {
    color: #2a9d8f;
    font-weight: 600;
    font-size: 1.1em;
}
