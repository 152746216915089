/* src/Styles/DiwaliCelebration.css */
.content {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .content img {
    width: 80%;
    height: auto;
    max-width: 600px;
    margin: 20px 0;
    border-radius: 10px;
  }
  
  
  
  /* Add some responsiveness for mobile screens */
  @media (max-width: 768px) {
    .content {
      padding: 15px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    .content img {
      width: 100%;
    }
  }
  